import React, { Component } from "react";
import Slider from "react-slick";
import {
    PopupboxContainer
} from 'react-popupbox';

// IMPORT SVG'S
import WIADecoLeaf1 from '../../assets/svg/When-in-Aruba-leaf-1-2.svg';
import SlideWIA from "./slide";

export default class AsNavFor extends Component {
    render() {
        let { activities, title } = this.props;
        return (
            <div>
                <div className="container triple-inset">
                    <div className="row justify-content-md-center triple-inset">
                        <img className="deco-wia-leaf-1" src={WIADecoLeaf1} alt="{alternative}" />
                        <div className="col-10 primary-bg-color inset-full-triple txt-align-center ov-vis">
                            <h1 className="default-secondary-color as-h2">{title}</h1>
                            <span className="deco-border deco-border-white center"></span>
                            {(activities && activities.length > 0) && <PopupboxContainer />}

                            {(activities && activities.length > 0) &&
                                <Slider
                                    ref={slider => (this.slider1 = slider)}
                                    arrows={true}
                                    infinite={true}
                                    slidesToShow={1}
                                    slidesToScroll={1}
                                    fade={true}
                                >
                                    {activities.map((activity, i) => <SlideWIA key={i} {...activity} />)}
                                </Slider>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
