import React, { Component } from 'react';

// IMPORT SVG'S
import WIADecoLeaf2 from '../../assets/svg/leaf-1.svg';

class Accommodation extends Component {
    render() {
        let { accommodations, description, title } = this.props;
        return (
            <div id="accommodations" className="container triple-inset">
                <div className="row justify-content-md-center triple-inset">
                    <div className="col-8 txt-align-center">
                        <h2 className="primary-color">{title}</h2>
                        <span className="deco-border"></span>
                        <p>{description}</p>
                    </div>
                </div>
                {(accommodations && accommodations.length > 0) &&
                    <div className="row justify-content-md-center">
                        {accommodations.map(({ address, image, link, name, }, i) =>
                            <div key={i} className="col-4">
                                <img className="deco-wia-leaf-2" src={WIADecoLeaf2} alt="{alternative}" />
                                <a href={link} target="_blank" rel="noopener noreferrer" className="card card-hotels">
                                    <img src={image.sizes['accommodations-thumb']} alt={image.alt} />
                                    <div className="default-secondary-bg-color">
                                        <div className="card-body">
                                            <h4 className="primary-color">{name}</h4>
                                            <p className="primary-color"><i className="fas fa-map-marker-alt"></i> {address}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default Accommodation;
