import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';

// IMPORT SUB COMPONENTS
import Accommodation from '../subcomponents/accommodation';

// IMPORT SVG'S
import OurStoryDeco1 from '../../assets/svg/our-story-leaf-1.svg';
import OurStoryDeco3 from '../../assets/svg/our-story-leaf-3.svg';
import GalleryDeco1 from '../../assets/svg/gallery-leaf.svg';
import Flower from '../../assets/svg/occommodation-flower.svg';

class Homepage extends Component {

    constructor(props) {
        super(props);
        let oneDay = 24*60*60*1000;
        let firstDate = new Date();
        let secondDate = new Date(new Date().getFullYear(),5,1);
        let daysLeft = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime())/(oneDay)));
        this.state = {
            fetching: true,
            dataRoute: `${props.siteURL}wp-json/better-rest-endpoints/v1/page/${props.slug}`,
            daysLeft,
            gallery: {},
            our_story: {},
            top_section: {},
        }
    }

    componentDidMount() {
        let { dataRoute } = this.state;
        fetch(dataRoute)
            .then(res => res.json())
            .then(({ acf }) => this.setState(_ => {
                return {
                    ...acf,
                    fetching: false
                };
            }, this.getEvents));
    }

    render() {

        let { fetching, top_section, our_story, gallery, accommodations, daysLeft } = this.state;
        if (fetching) return null;
        let { invitation_text, background_image } = top_section;
        let { description, hashtag, left_image, right_image } = our_story;
        let { left, middle_images, right } = gallery;
        return (
            <main id="main" className="mrg-btm">
                <div id="intro-background" style={{
                    backgroundImage: `url(${background_image.url})`,
                    backgroundPosition: 'bottom center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                </div>
                <div className="container triple-inset">
                    <div className="row justify-content-md-center">
                        <div className="col-8 txt-align-center">
                            <h2 className="mrg-top primary-color mutlibg-decoration-primary">
                                {invitation_text} <br />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-8 txt-align-center">
                            <div className="countdown">
                                <span className="text primary-color">Days</span>
                                <span className="numbers primary-color">{daysLeft}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-md-center triple-inset">
                        <div className="col-8 txt-align-center">
                            <Link className="btn btn-primary" to="/events">RSVP</Link>
                        </div>
                    </div>
                </div>
                <div id="our-story" className="mutlibg-decoration-secondary">
                    <div className="leaf-1">
                        <img src={OurStoryDeco1} alt="Leaf decoration" />
                    </div>
                    <div className="leaf-3">
                        <img src={OurStoryDeco3} alt="Leaf decoration" />
                    </div>
                    <div className="container-fluid triple-inset pos-rel">
                        <div className="row">
                            <div className="col txt-align-center">
                                <h3 className="primary-color bold-font">{our_story.title}</h3>
                                <span className="deco-border"></span>
                            </div>
                            <div className="row">
                                <div className="col-3" style={{
                                    backgroundImage: `url(${left_image ? left_image.url : 'https://picsum.photos/g/373/373/?blur?image=983'})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                                <div className="col-6 col-p-2">
                                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                    <h3 className="primary-color txt-capslock hashtag">{hashtag}</h3>
                                </div>
                                <div className="col-3" style={{
                                    backgroundImage: `url(${right_image ? right_image.url : 'https://picsum.photos/g/373/373/?blur?image=983'})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container triple-inset">
                    <div className="row justify-content-md-center">
                        <div className="col-4">
                            <img className="gallery-leaf-deco" src={GalleryDeco1} alt="Gallery Leaf" />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col">
                                    {left && left.map((image, i) => <img key={i} className="mrg-btm-half" src={image.url} alt={image.alt} />)}
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col">
                                    {middle_images && middle_images.map((image, i) => <img key={i} className="mrg-btm-half" src={image.url} alt={image.alt} />)}
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col">
                                    {right && right.map((image, i) => <img key={i} className="mrg-btm-half" src={image.url} alt={image.alt} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Accommodation {...accommodations} />
                <div className="container triple-inset">
                    <div className="row">
                        <div className="col-8"></div>
                        <div className="col-4">
                            <div className="flower">
                                <img src={Flower} alt="Accomodation flower" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Homepage;
