import React, { Component } from 'react';

// IMPORT SVG'S
import ContactLeadDeco1 from '../../assets/svg/contact-leaf.svg';

// COMPONENTS
import TextField from '../../components/subcomponents/TextField';
import TextArea from '../../components/subcomponents/TextArea';
import Validator, {
  ValueSetValidator,
  PhoneNumberValidator,
  EmailValidator
} from '../../components/subcomponents/Validators';

class ContactUspage extends Component {
  constructor(props) {
    super(props);
    this.validator = new Validator();
    this.state = {
      fetching: true,
      dataRoute: `${props.siteURL}wp-json/better-rest-endpoints/v1/page/${props.slug}`,
    }
  }

  componentDidMount() {
    let { dataRoute } = this.state;
    fetch(dataRoute)
    .then(res => res.json())
    .then(({ acf,title }) => this.setState(_ => {
        return {
            ...acf,
            title,
            fetching: false
        };
    }));
  }

  onSubmit = (event) => {
    let results = this.validator.validResult();

    console.log(results);

    event.preventDefault();
  }

  render() {
    let { fetching, title } = this.state;
    if (fetching) return null;
    return (
      <main id="main" className="contact-multi-bg">
        <div className="container triple-inset">
          <div className="row mrg-top justify-content-md-center">
            <div className="col-6 txt-align-center">
              <h1 className="primary-color">{title}</h1>
              <span className="deco-border"></span>
              <form className="form mrg-btm">
                <img className="contact-leaf-deco-1" src={ContactLeadDeco1} alt="Contact Lead Decoration" />
                <div className="form-group">
                  <TextField id="fullName" fieldClassName="form-control" placeholder="Full Name" validator={new ValueSetValidator(this.validator)} />
                </div>
                <div className="form-group">
                  <TextField id="phoneNumber" fieldClassName="form-control" placeholder="Phone Number" validator={new PhoneNumberValidator(this.validator)} />
                </div>
                <div className="form-group">
                  <TextField id="email" fieldClassName="form-control" placeholder="Email Address" validator={new EmailValidator(this.validator)} />
                </div>
                <div className="form-group mrg-btm-half">
                  <TextArea id="message" fieldClassName="form-control" placeholder="Message"  validator={new ValueSetValidator(this.validator)} />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary pull-right" onClick={ this.onSubmit } >Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ContactUspage;
