import React, { Component } from 'react';
import { BASE_URL } from '../../constants';

class Aside extends Component {

    constructor() {
        super();
        this.state = {
            fetching: true,
            columns: null,
            dataRoute: BASE_URL + 'wp-json/acf/v3/options/options-aside',
            background_image : {}
        }
    }

    componentDidMount() {
        fetch(this.state.dataRoute)
            .then(res => res.json())
            .then(({ acf }) => this.setState(_ => {
                return {
                    ...acf,
                    fetching: false
                };
            }));
    }

    render() {
        let { fetching, background_image, hashtag } = this.state;
        if (fetching) return null;
        return (
            <aside id="complementary" className="inset" style={{
                backgroundImage: `url(${background_image.sizes['aside-bg-img']})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="mask"></div>
                <div className="container">
                    <div className="row justify-content-md-center align-items-center">
                        <div className="col-6 txt-align-center">
                            <h3 className="default-secondary-color txt-capslock hashtag">{hashtag}</h3>
                            <span className="deco-border deco-border-white center"></span>
                        </div>
                    </div>
                </div>
            </aside>
        );
    }
}

export default Aside;
