import React, { Component } from "react";
import { PopupboxManager } from 'react-popupbox';

export default class SlideWIA extends Component {

    openPopupbox(image) {
        var content = <img src={image.sizes['activities-modal-img']} alt={image.alt} />;

        PopupboxManager.open({
            content,
            config: {
                titleBar: {
                    enable: true,
                    closeButton:true,
                    closeText: 'x',
                    position: 'top'
                },
                fadeIn: true,
                fadeInSpeed: 500,
                escClose: false,
                overlayClose:false,
                

            }
        });
    }

    render() {
        let { description, images, location, title } = this.props;
        return (
            <div className="carousel">
                <h2 className="default-secondary-color as-h3 txt-capslock">{title}</h2>
                <div className="row justify-content-md-center">
                    <div className="col-8">
                        <p className="default-secondary-color">{description}</p>
                    </div>
                </div>
                <div className="activities">
                    <div className="row justify-content-md-center">
                        {
                            (images && images.length > 0) && images.map((image, i) =>
                                <div key={i} className="show-thumbnail" onClick={(event) => this.openPopupbox(image, event)}>
                                    <img src={image.sizes['activities-thumb']} alt={image.alt} />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="row justify-content-md-center mrg-top">
                    <div className="col-8">
                        <p className="default-secondary-color"><i className="fas fa-map-marker-alt"></i> {location}</p>
                    </div>
                </div>
            </div>
        )
    }
}