import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';
import { BASE_URL } from '../../constants';
import Emblen from '../../assets/svg/nav-emblen.svg';

const NavItem = ({ type, link, title }) => {
    let path = `/${link.replace(BASE_URL, "")}`;
    if (type === 'Link') {
        return (
            <li className="nav-item">
                <Link className="nav-link" to={path}>{title}</Link>
            </li>
        );
    } else {
        return (
            <li className="nav-item">
                <img className="emblen-icon" src={Emblen} alt="Tristan &amp; Nunette" title="Tristan &amp; Nunette"/>
            </li>
        );
    }
}

class Header extends Component {

    constructor() {
        super();
        this.state = {
            nav: null,
            dataRoute: BASE_URL + 'wp-json/acf/v3/options/options-header'
        }
    }

    componentDidMount() {
        fetch(this.state.dataRoute)
            .then(res => res.json())
            .then(navItems => this.setState(_ => {
                return { nav: navItems.acf.menu_items };
            }));
    }

    render() {
        let { nav } = this.state;
        if (!nav) return null;
        return (
            <header id="header" className="inset">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav id="main-nav">
                                <ul className="nav nav-pills nav-fill">
                                    {nav.map((navItem, i) => <NavItem key={'navItem' + i} {...navItem} />)}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
