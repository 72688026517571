import React, { Component } from 'react';

class AdminAllRSVP extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            fetchingGuests: true,
            fetchingEvents: true,
        }
    }

    componentDidMount() {
        fetch(`${this.props.siteURL}get-guests/`)
            .then(res => res.json())
            .then((response) => {
                let guests = response.map(({ ...guest }) => guest);
                this.setState({ guests, fetchingGuests: false });
            });
        fetch(`${this.props.siteURL}get-events/`)
            .then(res => res.json())
            .then((response) => {
                let events = response.map(({ ...event }) => event);
                this.setState({ events, fetchingEvents: false });
            });
    }

    render() {
        let { fetchingGuests, fetchingEvents, guests, events } = this.state;
        if (fetchingGuests || fetchingEvents) return null;
        return (
            <main id="main" className="multibg-event">
                <div className="container triple-inset">
                    <div className="row triple-inset">
                        <div className="col-12 brd-pdng">
                            <table className="table table-rsvp">
                                <thead>
                                    <tr>
                                        <th>Guest</th>
                                        {events.map((event) => <th key={event.ID}>{event.title}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        guests && guests.map((guest) =>
                                            <tr key={guest.ID}>
                                                <td>{
                                                    guest.acf.guests.map((guest, i) =>
                                                        <div key={i}>{guest.full_name}<br /></div>
                                                    )
                                                }</td>
                                                {
                                                    events.map((event) =>
                                                        <td key={`${guest.ID}-${event.ID}`}>
                                                            {
                                                                guest.acf.guests.map(function (guest, i) {
                                                                    let retVal = '';
                                                                    for (let i = 0; i < guest.rsvp.length; i++) {
                                                                        let rsvp = guest.rsvp[i];
                                                                        if (rsvp.event === event.ID) {
                                                                            retVal = rsvp.rsvp;
                                                                        }
                                                                    }
                                                                    return <div key={i}>{retVal}<br /></div>;
                                                                })
                                                            }
                                                        </td>
                                                    )
                                                }
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default AdminAllRSVP;
