import React, { Component } from 'react';
import { BASE_URL } from '../../constants';

// SVGs
import TristanAndNunetteDeco from '../../assets/svg/header-name.svg';

class Loginpage extends Component {

    constructor() {
        super();
        this.state = {
            email: ''
        }
        this.updateInput = this.updateInput.bind(this);
        this.login = this.login.bind(this);
    }

    updateInput(event) {
        this.setState({ email: event.target.value });
    }

    login(event) {
        let { email } = this.state;
        fetch(`${BASE_URL}login/?email=${email}`)
        .then(response => response.json())
        .then(response => {
            if (response.result === 'loginOK') {
                localStorage.setItem('email', email);
                localStorage.setItem('id', response.id);
                //this.props.history.push(`/client/`);
                window.location.href = `/`;
            } else {
                this.setState({ loginFailed: true });
            }
        })
        .catch(error => console.error(error))
        event.preventDefault();
    }

    render() {
        let { email } = this.state;
        return (
            <main id="main" className="multibg-login">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-6 txt-align-center">
                            <img src={TristanAndNunetteDeco} alt="Tristan &amp; Nunette" />
                            <div className="primary-bg-color inset-full">
                                <h2 className="default-secondary-color">You're cordially invited to our wedding!</h2>
                                <form className="form">
                                    <div className="form-group">
                                        <input type="email" className="form-control" value={email} onChange={this.updateInput} placeholder="Enter your email to enter website..." />
                                        <button className="btn btn-secondary" onClick={this.login}>Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Loginpage;
