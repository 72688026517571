import React, { Component } from 'react';
import moment from 'moment';

export default class RSVPOverview extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            fetching: true,
            dataRoute: `${props.siteURL}wp-json/better-rest-endpoints/v1/page/${props.slug}`,
            showFormFeedback: false
        }
        this.onRSVPSelected = this.onRSVPSelected.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let { dataRoute } = this.state;
        fetch(dataRoute)
            .then(res => res.json())
            .then(({ acf, title }) => {
                this.setState({
                    ...acf,
                    title,
                    fetching: false
                }, this.getGuest)
            });
    }

    getGuest() {
        let guestId = localStorage.getItem('id');
        if (guestId) {
            fetch(`${this.props.siteURL}get-guests/?id[]=${guestId}`)
                .then(res => res.json())
                .then((response) => {
                    this.setState({ guestId, guests: response[0].acf.guests }, this.getEvents)
                });
        }
    }

    getEvents() {
        let { guests } = this.state;
        let events = [];
        for (let i = 0; i < guests.length; i++) {
            for (let j = 0; j < guests[i].rsvp.length; j++) {
                if (guests[i].rsvp[j].event) {
                    events.push(guests[i].rsvp[j].event);
                }
            }
        }
        if (events && events.length > 0) {
            let arg = '';
            for (let i = 0; i < events.length; i++) {
                arg += (i === 0) ? `id[]=${events[i]}` : `&id[]=${events[i]}`;
            }
            fetch(`${this.props.siteURL}get-events/?${arg}`)
                .then(res => res.json())
                .then((eventPosts) => {
                    this.setState({ eventPosts, currentItem: eventPosts[0] })
                });
        }
    }

    onRSVPSelected(eventPost, e) {
        this.setState({ currentItem: eventPost });
        e.preventDefault();
    }

    onHandleRSVP(e, fullName, eventId) {
        let { guests } = this.state;
        for (let i = 0; i < guests.length; i++) {
            if (guests[i].full_name === fullName) {
                for (let j = 0; j < guests[i].rsvp.length; j++) {
                    if (guests[i].rsvp[j].event === eventId) {
                        guests[i].rsvp[j].rsvp = e.target.value;
                    }
                }
            }
        }
        this.setState({ type: e.target.value, guests });
    }

    handleSubmit() {
        this.setState({showFormFeedback: true});

        let {guestId, guests} = this.state;
        let arg = '';
        for (let i = 0; i < guests.length; i++) {
            arg += `&guests[]=${JSON.stringify(guests[i])}`;
        }
        fetch(`${this.props.siteURL}rsvp/?guestId=${guestId}${arg}`)
            .then(response => response.json())
            .then(response => console.log('Success:', JSON.stringify(response)))
            .catch(error => console.error('Error:', error));
    }

    render() {
        let { fetching, eventPosts, currentItem, guests, title } = this.state;
        let showFormFeedback = this.state.showFormFeedback;

        if (fetching) return null;
        return (
            <main id="main" className="multibg-event">
                <div className="container triple-inset">
                    <div className="row justify-content-md-center mrg-top">
                        <div className="col-8 txt-align-center">
                            <h1 className="primary-color">{title}</h1>
                            <span className="deco-border"></span>
                            <p>For questions please contact us at hello@alwaysandforevery.com</p>
                        </div>
                    </div>
                    <div className="row triple-inset">
                        <div className="col-4 brd-pdng">
                            <ul className="list list-rsvp">
                                {
                                    (eventPosts && eventPosts.length > 0) &&
                                    eventPosts.map((eventPost) =>
                                        <li key={eventPost.ID} onClick={(e) => this.onRSVPSelected(eventPost, e)}>
                                            <span>{`${moment(eventPost.acf.begin_time).format('dd').toUpperCase()} ${moment(eventPost.acf.begin_time).format('MMMM D YYYY')}`}</span>
                                            <span className="as-h3 primary-color">{eventPost.acf.title}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                        {
                            currentItem &&
                            <div className="col-8 brd-pdng">
                                <h3 className="primary-color">{currentItem.acf.title}</h3>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <i className="fas fa-calendar secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">{moment(currentItem.acf.begin_time).format('dddd MMMM D YYYY')}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className="fas fa-clock secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">{moment(currentItem.acf.begin_time).format('h:mm a') + '-' + moment(currentItem.acf.end_time).format('h:mm a')}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className="fas fa-map-marker-alt secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">{currentItem.acf.location}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className="fas fa-calendar-check secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">RSVP by {currentItem.acf.rsvp_by}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                { showFormFeedback &&
                                    <div id="feedback-rsvp" className="alert alert-info">
                                        <p>Thank you for RSVPing. It has been saved.</p>
                                    </div>
                                }
                                {
                                    guests && guests.map((guest, index) => {
                                        for (let i = 0; i < guest.rsvp.length; i++) {
                                            if (guest.rsvp[i].event === currentItem.ID) {

                                                if(guest.full_name !== '') {
                                                    var FullName = <h4 className="primary-color">{guest.full_name}</h4>;
                                                } else {
                                                    var FullName = <h4 className="primary-color">Guest</h4>;
                                                }

                                                return (
                                                    <div key={index} className="row row-invitees align-items-center">
                                                        <div className="col-6">
                                                            {FullName}
                                                        </div>
                                                        <div className="col-6">
                                                            <form className="form">
                                                                <div className="switch-field" onChange={event => this.onHandleRSVP(event, guest.full_name, currentItem.ID)}>
                                                                    <input type="radio" id={`radio-one-${index}`} name="switch-one" value="accept" checked={guest.rsvp[i].rsvp === 'accept'} />
                                                                    <label htmlFor={`radio-one-${index}`} className="label-one">Accept</label>
                                                                    <input type="radio" id={`radio-two-${index}`} name="switch-one" value="decline" checked={guest.rsvp[i].rsvp === 'decline'} />
                                                                    <label htmlFor={`radio-two-${index}`} className="label-two">Decline</label>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        return null;
                                    })
                                }
                                <div className="row inset">
                                    <div className="col">
                                        <div className="btn btn-tertiary pull-right" onClick={this.handleSubmit}>Submit</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>
        );
    }
}