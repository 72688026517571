import React from 'react';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';
import Loginpage from './components/pages/login';

// LAYOUTS
import AppLayout from './components/layouts/AppLayout';

// INCLUDES
import './assets/css/app.min.css';


function PrivateRoute() {
    if (localStorage.getItem('email') !== null) {
        return (
            <Route component={AppLayout} />
        )
    } else {
        return <Route path="/*" component={Loginpage} />
    }
}

const App = ({ history }) => {
    return (
        <Router history={history}>
            <PrivateRoute />
        </Router>
    )
};

export default App;
