import React, { Component } from 'react';
import moment from 'moment';

import { Modal } from 'react-bootstrap';

class Eventspage extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            show: false,
            fetching: true,
            dataRoute: `${props.siteURL}wp-json/better-rest-endpoints/v1/page/${props.slug}`,
            showFormFeedback: false,
        }
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow(id, acf) {
        this.setState({
            show: true,
            itemTitle: acf.title,
            itemDate: moment(acf.begin_time).format('dddd, MMMM Do, YYYY'),
            itemTime: moment(acf.begin_time).format('h:mm a') + '-' + moment(acf.end_time).format('h:mm a'),
            itemLocation: acf.location,
            itemRSVPBy: acf.rsvp_by,
            itemID: id
        });
    }

    componentDidMount() {
        let { dataRoute } = this.state;
        fetch(dataRoute)
            .then(res => res.json())
            .then(({ acf }) => {
                this.setState({
                    ...acf,
                    fetching: false
                }, this.getGuest)
            });
    }

    getGuest() {
        let guestId = localStorage.getItem('id');
        if (guestId) {
            fetch(`${this.props.siteURL}get-guests/?id[]=${guestId}`)
                .then(res => res.json())
                .then((response) => {
                    this.setState({ guestId, guests: response[0].acf.guests }, this.getEvents)
                });
        }
    }

    getEvents() {
        let { guests } = this.state;
        let events = [];
        for (let i = 0; i < guests.length; i++) {
            for (let j = 0; j < guests[i].rsvp.length; j++) {
                if (guests[i].rsvp[j].event) {
                    events.push(guests[i].rsvp[j].event);
                }
            }
        }
        if (events && events.length > 0) {
            let arg = '';
            for (let i = 0; i < events.length; i++) {
                arg += (i === 0) ? `id[]=${events[i]}` : `&id[]=${events[i]}`;
            }
            fetch(`${this.props.siteURL}get-events/?${arg}`)
                .then(res => res.json())
                .then((eventPosts) => {
                    this.setState({ eventPosts })
                });
        }
    }

    onHandleRSVP(e, fullName, eventId) {
        let { guests } = this.state;
        for (let i = 0; i < guests.length; i++) {
            if (guests[i].full_name === fullName) {
                for (let j = 0; j < guests[i].rsvp.length; j++) {
                    if (guests[i].rsvp[j].event === eventId) {
                        guests[i].rsvp[j].rsvp = e.target.value;
                    }
                }
            }
        }
        this.setState({ type: e.target.value, guests });
    }

    handleSubmit() {

        this.setState({showFormFeedback: true});

        let { guestId, guests } = this.state;
        let arg = '';
        for (let i = 0; i < guests.length; i++) {
            arg += `&guests[]=${JSON.stringify(guests[i])}`;
        }
        
        fetch(`${this.props.siteURL}rsvp/?guestId=${guestId}${arg}`)
            .then(response => response.json())
            .then(response => console.log('Success:', JSON.stringify(response)))
            .catch(error => console.error('Error:', error));
    }

    render() {
        let { fetching, title, description, eventPosts, guests } = this.state;
        let showFormFeedback = this.state.showFormFeedback;

        if (fetching) return null;
        return (
            <main id="main" className="multibg-event">
                <div className="container triple-inset">
                    <div className="row justify-content-md-center mrg-top">
                        <div className="col-8 txt-align-center">
                            <h1 className="primary-color">{title}</h1>
                            <span className="deco-border"></span>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
                <div className="container triple-inset">
                    {
                        (eventPosts && eventPosts.length > 0) &&
                        eventPosts.map(({ ID, acf }) =>
                            <div key={ID} className="row mrg-btm-triple">
                                <div className="col-1"></div>
                                <div className="col-5">
                                    <h2 className="primary-color txt-capslock">{acf.title}</h2>
                                    <span className="deco-border deco-border-left"></span>
                                    <p dangerouslySetInnerHTML={{ __html: acf.description }}></p>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <i className="fas fa-tshirt secondary-color"></i>
                                                </td>
                                                <td>
                                                    <p className="secondary-color">{acf.dresscode}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fas fa-clock secondary-color"></i>
                                                </td>
                                                <td>
                                                    <p className="secondary-color">{moment(acf.begin_time).format('dddd, MMMM Do, YYYY')}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fas fa-clock secondary-color"></i>
                                                </td>
                                                <td>
                                                    <p className="secondary-color">{moment(acf.begin_time).format('h:mm a')} - {moment(acf.end_time).format('h:mm a')}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fas fa-map-marker-alt secondary-color"></i>
                                                </td>
                                                <td>
                                                    <p className="secondary-color">{acf.location}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fas fa-calendar-check secondary-color"></i>
                                                </td>
                                                <td>
                                                    <p className="secondary-color">RSVP by {acf.rsvp_by}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="btn btn-tertiary" onClick={() => this.handleShow(ID, acf)}>RSVP</div>
                                </div>
                                <div className="col-5">
                                    <div className="card card-hotels">
                                        <img className="mrg-btm-half" src={acf.image.sizes['events-thumb']} alt={acf.image.alt} />
                                    </div>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        )
                    }
                </div>

                <Modal show={this.state.show} onHide={this.handleClose}
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <div className="row">
                            <div className="col">
                                <h3 className="primary-color">{this.state.itemTitle}</h3>
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <i className="fas fa-calendar secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">{this.state.itemDate}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className="fas fa-clock secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">{this.state.itemTime}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className="fas fa-map-marker-alt secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">{this.state.itemLocation}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className="fas fa-calendar-check secondary-color"></i>
                                            </td>
                                            <td>
                                                <p className="secondary-color">RSVP by {this.state.itemRSVPBy}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        { showFormFeedback &&
                            <div id="feedback-rsvp" className="alert alert-info">
                                <p>Thank you for RSVPing. It has been saved.</p>
                            </div>
                        }
                        {
                            guests && guests.map((guest, index) => {
                                for (let i = 0; i < guest.rsvp.length; i++) {
                                    if (guest.rsvp[i].event === this.state.itemID) {
                                        return (
                                            <div key={index} className="row row-invitees align-items-center">
                                                <div className="col-6">
                                                    <h4 className="primary-color">{guest.full_name}</h4>
                                                </div>
                                                <div className="col-6">
                                                    <form className="form">
                                                        <div className="switch-field" onChange={event => this.onHandleRSVP(event, guest.full_name, this.state.itemID)}>
                                                            <input type="radio" id={`radio-one-${index}`} name="switch-one" value="accept" checked={guest.rsvp[i].rsvp === 'accept'} />
                                                            <label htmlFor={`radio-one-${index}`} className="label-one">Accept</label>
                                                            <input type="radio" id={`radio-two-${index}`} name="switch-one" value="decline" checked={guest.rsvp[i].rsvp === 'decline'} />
                                                            <label htmlFor={`radio-two-${index}`} className="label-two">Decline</label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                                return null;
                            })
                        }
                        <div className="row inset">
                            <div className="col">
                                <p>For questions please contact us at hello@alwaysandforevery.com</p>
                            </div>
                        </div>
                        <div className="row inset">
                            <div className="col">
                                <div className="btn btn-tertiary pull-right" onClick={this.handleSubmit}>Submit</div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </main>
        );
    }
}

export default Eventspage;
