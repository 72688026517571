import React, { Component } from 'react';

// IMPORT SUB COMPONENTS
import Accommodation from '../subcomponents/accommodation';
import NavSlider from '../carousel/carouselWIA';

// IMPORT SVG'S
import GalleryDeco1 from '../../assets/svg/gallery-leaf.svg';

class WheninArubapage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            dataRoute: `${props.siteURL}wp-json/better-rest-endpoints/v1/page/${props.slug}`,
        }
    }

    componentDidMount() {
        let { dataRoute } = this.state;
        fetch(dataRoute)
            .then(res => res.json())
            .then(({acf}) => this.setState(_ => {
                return {
                    ...acf,
                    fetching: false
                };
            }));
    }

    render() {
        let { fetching, activities, accommodations, title, } = this.state;
        if (fetching) return null;
        return (
            <main id="main" className="wia-multi-bg">
                <NavSlider activities={activities} title={title} />
                <Accommodation {...accommodations} />
                <div className="container triple-inset">
                    <div className="row justify-content-md-center">
                        <div className="col-4">
                            <img src={GalleryDeco1} alt="Gallery Leaf" />
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default WheninArubapage;
