import { Route } from 'react-router-dom';
import React, { Component } from 'react';
import ScrollToTop from 'react-router-scroll-top';
import { BASE_URL } from '../../constants';

// COMPONENTS
import Header from '../header/header';
import Homepage from '../pages/homepage';
import Eventspage from '../pages/events';
import WheninArubapage from '../pages/wheninaruba';
import Gallerypage from '../pages/gallery';
import ContactUspage from '../pages/contactus';
import RSVPOverview from '../pages/rsvpoverview';
import AdminAllRSVP from '../pages/AdminAllRSVP';
import Aside from '../aside/aside';
import Footer from '../footer/footer';

const identifyComponent = (page) => {
    const components = {
        'index.php': Homepage,
        'events.php': Eventspage,
        'when-in-aruba.php': WheninArubapage,
        'gallery.php': Gallerypage,
        'contact.php': ContactUspage,
        'rsvp.php': RSVPOverview
    }
    if (page.template !== 'default' && page.template) {
        return components[page.template];
    } else {
        return page
    }
}

class AppLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            pageList: [],
        };
    }

    componentDidMount() {
        const endpoint = `${BASE_URL}get-pages`;
        fetch(endpoint)
            .then(response => response.json())
            .then(pageList => this.setState(_ => {
                return { pageList, fetching: false };
            }))
            .catch(error => console.error(error))
    }

    render() {
        let { fetching, pageList } = this.state;
        if (fetching) return null;
        return (
            <div className="App">
                <Header />
                <ScrollToTop />
                <Route path="/" render={(props) => <Homepage siteURL={BASE_URL} {...props} slug='home' />} exact />
                {
                    pageList.map((page) => {
                        let Template = identifyComponent(page)
                        let pageID = page.id;
                        let parent = page.parent;
                        return (
                            <Route
                                key={pageID}
                                path={`${parent ? '/' + parent : ''}/${page.slug}`}
                                render={(props) => <Template pageID={pageID} siteURL={BASE_URL} slug={page.slug} {...props} />}
                            />
                        )
                    })
                }
                {
                    ['andre@cr38te.com', 'rudy@cr38te.com', 'tristan.every@gmail.com', 'nunettemaduro@gmail.com'].includes(localStorage.getItem('email')) &&
                    <Route path="/admin-rsvp-overview" render={(props) => <AdminAllRSVP siteURL={BASE_URL} {...props} />} exact />
                }
                <Aside />
                <Footer />
            </div>
        );
    }
}

export default AppLayout;