import React, {Component} from 'react';

export default class TextField extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || ''
        }
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onGetValidateValues = this.onGetValidateValues.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this._inputtype = (props) => <input {...props} />;
    }

    componentWillMount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(this.onGetValidateValues);
            this.props.validator.setComponentOnValidated(this.onValidated);
            this.props.validator.componentHasMounted();
        }
    }
    componentWillUnmount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(null);
            this.props.validator.setComponentOnValidated(null);
            this.props.validator.componentHasUnmounted();
        }
    }

    onGetValidateValues() {
        let value = this.state.value ? this.state.value.trim() : this.state.value;
        return {id: this.props.id, value: value};
    }

    onValidated(results) {
        this.setState(results);
    }

    onChange(event) {
        this.setState({value: event.target.value});
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    onBlur(event) {
        if (this.props.validator) {
            this.props.validator.validate(event.target.value);
        }
    }

    render() {
        let {
            id, 
            placeholder, 
            style, 
            autoComplete=false, 
            autoCorrect=false,
            autoCapitalize=false,
            spellCheck=false,
            className='TextField',
            fieldClassName=''
        } = this.props;
        let {value, error} = this.state;
        let Input = this._inputtype;
        return (
            <div id={id} className={className}>
                <Input 
                    className= {[fieldClassName, (error ? 'Error':'')].join(' ') }
                    placeholder={placeholder} 
                    value={value} 
                    onChange={this.onChange}
                    onBlur={this.onBlur} 
                    style={style} 
                    autoComplete={autoComplete ? 'on' : 'off'} 
                    autoCorrect={autoCorrect ? 'on' : 'off'} 
                    autoCapitalize={autoCapitalize ? 'on' : 'off'} 
                    spellCheck={spellCheck ? 'true' : 'false'}
                />
                {error ? <span className='Error'><i class="fas fa-exclamation-triangle"></i> {error}</span> : null}
            </div>
        );
    }
}
