import React, { Component } from 'react';
import Lightbox from 'react-lightbox-component';
import "react-lightbox-component/build/css/index.css";

// IMPORT SVG'S
import GalleryLeafDeco1 from '../../assets/svg/gallery-leaf-1.svg';
import GalleryLeafDeco2 from '../../assets/svg/gallery-leaf-2.svg';

export default class Gallerypage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            dataRoute: `${props.siteURL}get-event-gallery/`,
        }
    }

    componentDidMount() {

        let { dataRoute } = this.state;
        fetch(dataRoute)
            .then(res => res.json())
            .then(({ title, description, events, selectedGallery }) => {
                let eventName = selectedGallery ? selectedGallery.event.post_title : '';
                this.setState({ title, description, events, selectedGallery: selectedGallery.gallery, eventName, fetching: false });
            })
    }

    onTabClick = ({ ID, post_title }, event) => {
        fetch(`${this.props.siteURL}get-event-gallery/?event-id=${ID}`)
            .then(res => res.json())
            .then(({selectedGallery}) => {
                this.setState({ selectedGallery, eventName: post_title });
            })
        event.preventDefault();
    }

    render() {
        let { fetching, title, description, events, selectedGallery, eventName } = this.state;
        if (fetching) return null;
        debugger;
        return (
            <main id="main">
                <div className="container triple-inset">
                    <div className="row mrg-top justify-content-md-center">
                        <div className="col-8 txt-align-center">
                            <img className="gallery-leaf-deco-1" src={GalleryLeafDeco1} alt="Gallery Leaf Decoration" />
                            <h1 className="primary-color">
                                <small className="mrg-top">{title}</small>
                            </h1>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
                {
                    events &&
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <ul className="list list-gallery">
                                    {events.map((event, i) =>
                                        <li key={`${i}-event-tab-${event.ID}`} onClick={e => this.onTabClick(event, e)}>
                                            <span className="as-h3 primary-color">{event ? event.post_title : ''}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                {
                    selectedGallery &&
                    <div className="container mrg-btm">
                        <div className="row">
                            <img className="gallery-leaf-deco-2" src={GalleryLeafDeco2} alt="Gallery Leaf Decoration" />
                            <Lightbox
                                images={
                                    selectedGallery.map(({large, width, alt}) => {
                                        return ({
                                            src: large,
                                            width: width,
                                            description: eventName,
                                            alt: alt
                                        })
                                    })}
                                showImageModifiers={false}
                                clickOutsideToClose={true}
                                renderImageFunc={(idx, image, toggleLightbox) => {
                                    return (
                                        <div className={image.width < 300 ? 'normal' : 'landscape'} key={idx} style={{
                                            backgroundImage: `url(${image.src})`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center',
                                            backgroundSize: 'cover',
                                            height: '250px'
                                        }} onClick={toggleLightbox.bind(null, idx)}>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </div>
                }
            </main>
        );
    }
}
