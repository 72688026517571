import React, { Component } from 'react';
import { BASE_URL } from '../../constants';

class Footer extends Component {

    constructor() {
        super();
        this.state = {
            fetching: true,
            columns: null,
            dataRoute: BASE_URL + 'wp-json/acf/v3/options/options-footer'
        }
    }

    componentDidMount() {
        fetch(this.state.dataRoute)
            .then(res => res.json())
            .then(footer => this.setState(_ => {
                return {
                    footerText: footer.acf.footer_text,
                    fetching: false
                };
            }));
    }

    render() {
        let { fetching,  footerText } = this.state;
        if (fetching) return null;
        return (
            <footer id="footer" className="double-inset">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-8 txt-align-center">
                            <p className="primary-color">{footerText} <a href="https://www.cr38te.com" target="_blank" rel="noopener noreferrer" title="Design/Develop by CR38TE" className="primary-color">CR38TE</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
